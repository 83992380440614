<template>
  <section>
    <template v-if="minDate && maxDate">
      <table-header
        v-bind="{
        minDate,
        maxDate,
        createTitle: !hasPermission
        ? 'Добавить заказ' : '',
        currentPage: page,
      }"
        @update="onUpdateFilter"
        @reset="onResetFilter"
        @create="onAdd"
      >
        <template #filters>
          <b-col
            class="mb-1 mb-md-1"
            lg="2"
            md="6"
            sm=12
          >
            <common-select
              v-bind="{
              name: 'source_id',
              value: source_id,
              options: sources,
              placeholder: 'Источники заказов',
              valueField: 'id',
              textField: 'name'
            }"
              @change="onUpdateFilter"
            />
          </b-col>

          <b-col
            lg="2"
            md="6"
            sm="12"
            class="mb-1 mb-md-1"
          >
            <common-select
              v-bind="{
              name: 'pay_status',
              value: pay_status,
              options: orderPaymentStatusCollection,
              placeholder: 'Статус оплаты',
            }"
              @change="onUpdateFilter"
            />
          </b-col>

          <b-col
            lg="2"
            md="6"
            sm="12"
            class="mb-1 mb-md-1"
          >
            <common-select
              v-bind="{
              name: 'filterStatus',
              value: filterStatus,
              options: orderStatusCollection,
              placeholder: 'Статус заказов',
            }"
              @change="onUpdateFilter"
            />
          </b-col>
        </template>
      </table-header>
    </template>
    <table-body
      v-bind="{
        currentPage: page,
        fields,
        pageCount,
        itemCount,
        pageSize: page_size,
      }"
      @update:page-size="onPerPageChange"
      @update:page="onPageChange"
      @settings-confirm="updateTableConfig"
      is-column-settings
    >
      <b-table
        class="position-relative"
        v-bind="{
          items: orders,
          fields,
          striped,
          hover,
          responsive,
          showEmpty: orders && !orders.length && !busy,
          emptyText,
          parePage: page_size,
          busy: loading,
        }"
        @sort-changed="onSort"
      >
        <template #empty="scope">
          <span class="text-left">{{ scope.emptyText }}</span>
        </template>
        <template #cell(status)="{ item }">
          <b-button-group style="width: 160px;">
            <b-button
              v-bind="{
              block: true,
              disabled: hasPermission,
              variant: style.status[item.status]
            }"
              @click="onStatus({ ...item })"
            >
              {{ orderStatus[item.status] }}
            </b-button>
          </b-button-group>
        </template>

        <template #cell(source)="{ item }">
          {{
            sources.find(i => i.id === item.source_id)
            ? sources.find(i => i.id === item.source_id).name
            : ''
          }}
        </template>
        <template #cell(pay_status)="{ item }">
          {{ orderPaymentStatusCollection[item.pay_status - 1]
              ? orderPaymentStatusCollection[item.pay_status - 1].text
              : ''
          }}
        </template>

        <template #cell(delivery_time)="{ item }">
          <p> с {{ $dayjs(item.delivery_interval_from * 1000).format('DD MMM HH:mm') }} </p>
          <p> по {{ $dayjs(item.delivery_interval_to * 1000).format('DD MMM HH:mm') }} </p>
        </template>

        <template #cell(delivery_status)="{ item }">
          {{ deliveryTypeCollection.find(type => type.value === item.delivery_status).text }}
        </template>

        <template #cell(manager)="{ item }">
          {{ item.manager_full_name }}
        </template>

        <template #cell(courier)="{ item }">
          {{ item.courier_full_name }}
        </template>

        <template #cell(composition_photo)="{ item }">
          <template v-if="item.composition_photo.length">
            <template v-for="(photo, index) in item.composition_photo">
              <div
                @click="onImage({ ...item })"
                :key="index"
                :class="index < item.composition_photo.length - 1 ? 'mb-1' : ''"
              >
                <b-img
                  v-bind="{
                    src: `${url}${photo.photo}`,
                    alt: '',
                    width: '100px',
                  }"
                  @click="activeImgUrl = `${url}${photo.photo}`"
                ></b-img>
              </div>
            </template>
          </template>
        </template>

        <template #cell(client_phone)="{ item }">
          <a :href="`tel:+${item.client_phone}`">{{ item.client_phone | VMask('+#(###) ###-##-##') }}</a>
        </template>

        <template #cell(recipient_phone)="{ item }">
          <a :href="`tel:+${item.recipient_phone}`">{{ item.recipient_phone | VMask('+#(###) ###-##-##') }}</a>
        </template>

        <template #cell(actions)="{ item }">
          <table-buttons
            v-bind="{
              disabled: hasPermission,
            }"
            @edit="onEdit({ ...item })"
            @delete="onDelete({ ...item })"
          ></table-buttons>
        </template>

        <template #table-busy>
          <div class="text-primary my-2">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <span>Загрузка...</span>
          </div>
        </template>
      </b-table>
    </table-body>

    <common-dialog
      v-bind="{
        title: modal.delete.visible
          ? modal.delete.title
          : modal.image.visible
            ? modal.image.title
            : modal.status.title,
        value: modal.delete.visible || modal.image.visible || modal.status.visible,
        okOnly: modal.image.visible,
        okTitle: modal.delete.visible
          ? modal.delete.okTitle
          : modal.image.visible
            ? modal.image.okTitle
            : modal.status.okTitle,
        hideHeader: modal.image.visible,
        hideFooter: modal.image.visible,
        size: modal.image.visible ? 'md' : '',
        modalClass: modal.image.visible ? 'app-modal-img' : '',
      }"
      @confirm="onConfirm"
    >
      <template v-if="modal.delete.visible">
        <p> {{ `${modal.delete.content} ${id}?`}} </p>
      </template>
      <template v-if="modal.image.visible">
        <div><img :src="activeImgUrl" /></div>
      </template>
      <template v-if="modal.status.visible">
        <common-select
          v-bind="{
              name: 'status',
              value: status,
              options: delivery_status === 0 ? orderStatusCollection.filter(({ value }) => value !== 3)  : orderStatusCollection,
            }"
          @change="onStatusChange"
        />
      </template>
    </common-dialog>
  </section>
</template>

<script>
import { AuthNameSpace, RoleCollection } from '@/store/modules/auth/types'
import { mapState, mapActions } from 'vuex'

import { OrderNameSpace, OrderActionTypes } from '@/store/modules/order/types'
import { SourceNameSpace, SourceActionTypes } from '@/store/modules/source/types'

import { BTable } from 'bootstrap-vue'

import TableHeader from '@/components/common/table/TableHeader'
import TableBody from '@/components/common/table/TableBody'
import TableButtons from '@/components/common/buttons/TableButtons'

import { columns, modal } from '@/config/orders'
import { statusCollection, orderPaymentStatusCollection, orderStatusCollection, OrderStatus, deliveryTypeCollection } from '@/config/shared'
import { duration, BASE_URL } from '@/config'
import { throttle } from 'lodash'

import { tableConfigSetup, tableConfigFormatBeforeSave } from '@/mixins/table-columns-controller'

export default {
  name: 'Orders',
  mixins: [tableConfigSetup, tableConfigFormatBeforeSave],
  components: {
    BTable,
    TableHeader,
    TableBody,
    TableButtons,
    CommonSelect: () => import('@/components/common/common-select'),
    CommonDialog: () => import('@/components/common/common-dialog.vue'),
  },
  data() {
    return {
      delivery_status: 0,
      id: '',
      filterStatus: null,
      status: null,
      source_id: null,
      date_from: '',
      date_to: '',
      search: '',
      page_size: 10,
      page: 1,
      pay_status: null,
      columns: null,
      columnsUpdateSetup: null,
      city_id: this.active_city,
      modal,
      remove_completed_and_approved_filter: 0,
      // TODO handle req for update timeout
      timer: {
        id: '',
        timeout: 180000,
      },
      activeImgUrl: '',
      sort_by: {
        sortBy: 'interval_to',
        sortDesc: 'asc',
      },
      // TODO update on back req for get time
      busy: true,
      time: true,
      loading: true,
    }
  },
  props: {
    striped: {
      type: Boolean,
      default() {
        return true
      },
    },
    hover: {
      type: Boolean,
      default() {
        return true
      },
    },
    responsive: {
      type: Boolean,
      default() {
        return true
      },
    },
    emptyText: {
      type: String,
      default() {
        return 'Нет данных'
      },
    },
    url: {
      type: String,
      default() {
        return BASE_URL
      },
    },
  },
  computed: {
    ...mapState(AuthNameSpace, {
      userData: 'userData',
      active_city: 'city_id',
    }),

    ...mapState(OrderNameSpace, {
      orders: 'orders',
      config: 'config',
      pageCount: 'pageCount',
      itemCount: 'itemCount',
      minDate: 'date_from',
      maxDate: 'date_to',
    }),

    ...mapState(SourceNameSpace, {
      sources: 'sources',
    }),

    fields() {
      return this.tableConfigSetup({ local_config: columns, response_config: this.config })
    },

    statusCollection() {
      return statusCollection
    },

    orderPaymentStatusCollection() {
      return orderPaymentStatusCollection
    },

    orderStatusCollection() {
      return orderStatusCollection
    },

    orderStatus() {
      return OrderStatus
    },

    deliveryTypeCollection() {
      return deliveryTypeCollection
    },

    style() {
      return {
        status: {
          0: 'light',
          1: 'success',
          2: 'info',
          3: 'warning',
          4: 'primary',
        },
      }
    },
    hasPermission() {
      return this.userData.role === RoleCollection.florist || this.userData.role === RoleCollection.courier
    },
  },

  async beforeMount() {
    await this.loadTableConfig()
    await this.loadCollection()
    // TODO update on back req for get time
    this.time = false
    await this.loadCollection()
    await this.loadSourceCollection()

    this.$nextTick(() => {
      this.busy = false
    })

    this.timer.id = setInterval(
        async function({ timer, loadCollection }) {
          if (timer.timeout > 0) {
            timer.timeout -= 1
            await loadCollection()
          } else {
            timer.timeout = timeout
            await loadCollection()
          }
        },
        this.timer.timeout,
        this,
    )
  },

  beforeDestroy() {
    clearInterval(this.timer.id)
    window.removeEventListener('scroll', this.onScroll, true)
  },

  methods: {
    ...mapActions(OrderNameSpace, {
      [OrderActionTypes.LoadOrderCollection]: OrderActionTypes.LoadOrderCollection,
      [OrderActionTypes.DeleteOrder]: OrderActionTypes.DeleteOrder,
      [OrderActionTypes.LoadOrder]: OrderActionTypes.LoadOrder,
      [OrderActionTypes.UpdateOrder]: OrderActionTypes.UpdateOrder,
      [OrderActionTypes.LoadOrderTableConfig]: OrderActionTypes.LoadOrderTableConfig,
      [OrderActionTypes.UpdateOrderTableConfig]: OrderActionTypes.UpdateOrderTableConfig,
      [OrderActionTypes.ChangeOrderStatusManagerAndAdmin]: OrderActionTypes.ChangeOrderStatusManagerAndAdmin,
    }),

    ...mapActions(SourceNameSpace, {
      [SourceActionTypes.LoadSourceCollection]: SourceActionTypes.LoadSourceCollection,
    }),

    loadCollection: throttle(async function() {
      this.loading = true
      if (this.filterStatus === 5) this.remove_completed_and_approved_filter = 1
      else this.remove_completed_and_approved_filter = 0
      await this[OrderActionTypes.LoadOrderCollection]({ ...this.$data })
      if (this.orders) this.loading = false
    }, duration),

    loadSourceCollection: throttle(async function() {
      await this[SourceActionTypes.LoadSourceCollection]({ page_size: 50 })
    }, duration),

    async loadTableConfig() {
      await this[OrderActionTypes.LoadOrderTableConfig]()
    },

    async updateTableConfig({ settings }) {
      await this[OrderActionTypes.UpdateOrderTableConfig]({ settings: this.tableConfigFormatBeforeSave({ settings }) })
    },

    delete: throttle(async function() {
      await this[OrderActionTypes.DeleteOrder]({ ...this.$data })
    }, duration),

    update: throttle(async function() {
      await this[OrderActionTypes.UpdateOrder](this.$data)
    }, duration),

    async updateStatus({ id, status }) {
      await this[OrderActionTypes.ChangeOrderStatusManagerAndAdmin]({ id, status })
    },

    onAdd() {
      this.$router.push({ name: 'order-base-new' })
    },

    onEdit({ id }) {
      this.$router.push(`${this.$route.path}/${id}`)
    },

    onDelete({ id }) {
      this.id = id

      this.modal.delete.visible = true
    },

    onImage({ id }) {
      this.id = id

      this.modal.image.visible = true
    },

    onStatus({ id, status, delivery_status }) {
      this.id = id
      this.status = status
      this.delivery_status = delivery_status

      this.modal.status.visible = true
    },

    async onStatusChange(context) {
      Object.assign(this.$data, context)
    },

    async onConfirm({ confirmed }) {
      if (confirmed) {
        try {
          if (this.modal.status.visible) {
            if (confirmed) {
              await this.updateStatus({ ...this.$data })
              this.status = ''
              await this.loadCollection()
            }
          }

          if (this.modal.delete.visible) {
            if (confirmed) {
              await this.delete()

              if (this.orders.length === 1 && this.page !== 1) this.page -= 1

              await this.loadCollection()
            }
          }
        } catch {
          for (const type in this.modal) {
            if (this.modal[type].visible) {
              this.modal[type].visible = false
            }
          }
        }
      }

      for (const type in this.modal) {
        if (this.modal[type].visible) {
          this.modal[type].visible = false
        }
      }
    },

    async onUpdateFilter(context) {
      Object.assign(this.$data, context)
      this.page = 1
      await this.loadCollection()
    },

    async onResetFilter(context) {
      // Object.assign(this.$data, context)
      Object.assign(this.$data, { date_from: '', date_to: '' })

      // console.log(context)
      // if (!context.date_to && !context.date_from) {
      //   Object.assign(this.$data, { date_from: this.minDate, date_to: this.maxDate })
      // }

      this.source_id = null
      this.pay_status = null
      this.filterStatus = null

      await this.loadCollection()
    },

    async onPerPageChange(count) {
      this.page = 1
      this.page_size = parseInt(count, 10)
      await this.loadCollection()
    },

    async onPageChange(count) {
      this.page = parseInt(count, 10)
      await this.loadCollection()
    },

    phoneToMaskView({ phone }) {
      return phoneToMaskView({ phone })
    },

    async onSort({ sortBy, sortDesc }) {
      if (sortBy) {
        if (sortBy !== this.sort_by.sortBy) {
          this.sort_by.sortBy = sortBy === 'delivery_time' ? 'interval_to' : sortBy
        }
        this.sort_by.sortDesc = sortDesc ? 'desc' : 'asc'
      }

      await this.loadCollection()
    },
  },
  watch: {
    async active_city(city_id) {
      this.city_id = city_id
      if (this.page > 1) this.page = 1
      Object.assign(this.$data, { date_from: '', date_to: '' })
      await this.loadCollection()
    },
  },
}
</script>

