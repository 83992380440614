const columns = [
  {
    key: 'status',
    label: 'Статус',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'source',
    label: 'Источник',
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'number',
    label: 'Номер заказа',
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'delivery_time',
    label: 'Время доставки/самовывоза',
    sortable: true,
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'number_daily',
    label: '№',
    sortable: true,
    thClass: '',
    tdClass: '',
  },
  {
    key: 'delivery_address',
    label: 'Адрес доставки',
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'delivery_comment',
    label: 'Коментарий к адресу',
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'delivery_service',
    label: 'Курьерская служка',
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'delivery_status',
    label: 'Тип доставки',
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'client_full_name',
    label: 'ФИО заказчика',
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'client_phone',
    label: 'Номер заказчика',
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'recipient_full_name',
    label: 'ФИО получателя',
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'recipient_phone',
    label: 'Номер получателя',
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'postcard_text',
    label: 'Текст открытки',
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'total_price',
    label: 'Сумма к оплате',
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'delivery_price',
    label: 'Стоимость доставки',
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'pay_status',
    label: 'Статус оплаты',
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'manager',
    label: 'Ответственный менеджер',
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'courier',
    name: 'courier',
    label: 'Назначенный курьер',
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'composition_photo',
    name: 'composition_photo',
    label: 'Фото композиции',
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'composition_comment',
    name: 'composition_comment',
    label: 'Коментарий к композиции',
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'order_proceeds',
    name: 'order_proceeds',
    label: 'Выручка с заказа',
    status: '',
    thClass: '',
    tdClass: '',
  },
  {
    key: 'actions',
    label: '',
    name: 'actions',
    hidden: true,
    status: '',
    thClass: '',
    tdClass: '',
  },
]

const modal = {
  delete: {
    title: 'Вы действительно хотите удалить заказ',
    content: 'Вы действительно хотите удалить заказ №',
    visible: false,
    okTitle: 'Удалить',
  },
  image: {
    title: 'Фото композиции',
    visible: false,
    okTitle: 'Закрыть',
  },
  status: {
    title: 'Изменить статус',
    visible: false,
    okTitle: 'Изменить',
  },
}

export { columns, modal }
