export const tableConfigSetup = {
  methods: {
    tableConfigSetup({
      local_config,
      response_config,
      exclude_columns = ['actions'],
    }) {
      if (response_config.data) {
        // Настройка колонок таблицы
        const array = []

        local_config.forEach((column, i) => {
          // К конфигу на фронте добавляем данные получаемые с сервера
          const result = response_config.data.find(item => {
            return column.key === item.name
          })
          array.push({
            ...local_config[i],
            ...result,
          })
        })

        const result = array.map(item => {
          // Убираем скрытые колонки добавляя класс к td и th
          return {
            ...item,
            thClass: item.status ? 'text-center align-middle' : 'd-none',
            tdClass: item.status ? 'text-center align-middle' : 'd-none',
          }
        })

        result.forEach(item => {
          // Убираем класс с лишних колонок
          if (exclude_columns.includes(item.key)) {
            item.thClass = ''
            item.tdClass = ''
          }
        })

        return result
      }
    },
  },
}

export const tableConfigFormatBeforeSave = {
  methods: {
    tableConfigFormatBeforeSave({ settings }, exclude_keys = [
      'actions',
    ]) {
      const data = settings.map(item => {
        return { name: item.name, status: item.status ? 1 : 0 }
      })
      // Reverse loop to remove unwanted
      for (let i = data.length - 1; i >= 0; i--) {
        if (exclude_keys.includes(data[i].name)) {
          data.splice(i, 1)
        }
      }
      return data
    },
  },
}
